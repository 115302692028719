import { useRollbar } from '@rollbar/react'
import { useState } from 'react'
import VideoLinkIcon from 'shared/icons/video-link-icon'
import SelectUrlModal from 'modules/attachments/components/select-url-modal'
import { fetchOembed } from 'modules/attachments/utils/attachment-utils'
import {
  AttachmentTypeEnum,
  AttachmentUploadStatusEnum,
} from 'modules/community/types/post-attachment'
import { usePostAttachmentsContext } from './context'

export interface EmbeddedVideoAttachmentProps {
  className?: string
}

const EmbeddedVideoAttachment = ({ className }: EmbeddedVideoAttachmentProps) => {
  const rollbar = useRollbar()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { addAttachment, updateAttachment } = usePostAttachmentsContext()

  const handleEmbedVideo = async (url: string) => {
    const attachmentId = addAttachment({
      id: crypto.randomUUID(),
      status: AttachmentUploadStatusEnum.Uploading,
      url,
      previewUrl: null,
      type: AttachmentTypeEnum.EmbeddedVideo,
    })

    try {
      const videoMetadata = await fetchOembed(url)

      updateAttachment(attachmentId, {
        status: AttachmentUploadStatusEnum.Success,
        previewUrl: videoMetadata.thumbnail_url,
      })
    } catch (error) {
      rollbar.error('failed to upload embedded video url', error as Error)
      updateAttachment(attachmentId, {
        status: AttachmentUploadStatusEnum.Failed,
      })
    }
  }

  return (
    <>
      <button onClick={() => setIsModalOpen(true)} type="button" className={className}>
        <VideoLinkIcon className="h-6 w-6 fill-darkblue stroke-darkblue" />
      </button>
      <SelectUrlModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSuccess={handleEmbedVideo}
      />
    </>
  )
}

export default EmbeddedVideoAttachment
