import { Trans } from 'next-i18next'
import { Checkbox } from 'shared/components/checkbox'
import { ACTIVITY_CLEANUP_PERIOD_DURATION } from 'modules/community/const/post-consts'
import { useCommunityWithTopics } from 'modules/community/hooks/use-community-with-topics'
import { MemberInterface } from 'modules/community/types/member-interface'

interface RemoveRecentActivitiesCheckbox {
  label?: string
  memberDisplayName?: MemberInterface['displayName']
  checked: boolean
  onCheck: (value: boolean) => void
}

export const RemoveRecentActivitiesCheckbox = ({
  label,
  memberDisplayName,
  checked,
  onCheck,
}: RemoveRecentActivitiesCheckbox) => {
  const { data: community } = useCommunityWithTopics()

  return (
    <div className="flex flex-col justify-start gap-2">
      <p>{label}</p>
      <div className="mt-2 flex items-start [&>label]:items-start">
        <Checkbox
          label={
            <Trans
              i18nKey={'settings.form.ban_user_delete_recent_activity_label'}
              values={{
                days: ACTIVITY_CLEANUP_PERIOD_DURATION,
                member: memberDisplayName,
                community: community?.name,
              }}
            />
          }
          value={checked}
          onChange={onCheck}
        />
      </div>
    </div>
  )
}
