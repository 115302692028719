import ReactPlayer from 'react-player/lazy'
import { EmbeddedVideoAttachment } from 'modules/community/types/post-attachment'

export interface EmbeddedVideoGalleryPreviewProps {
  attachment: EmbeddedVideoAttachment
}

const EmbeddedVideoGalleryPreview = ({ attachment }: EmbeddedVideoGalleryPreviewProps) => {
  if (!attachment.url) return null

  return (
    <article
      key={attachment.id}
      className="inline-flex w-full flex-shrink-0 snap-center justify-center px-20 py-10 lg:px-80 lg:py-40"
    >
      <ReactPlayer controls width="100%" height="100%" url={attachment.url} />
    </article>
  )
}

export default EmbeddedVideoGalleryPreview
