import CheckCheckboxIcon from '../icons/check-checkbox-icon'

interface CheckboxProps {
  value: boolean
  onChange: (value: boolean) => void
  label?: string | JSX.Element
  disabled?: boolean
  isFetching?: boolean
}

export const Checkbox = ({ value, onChange, label, disabled, isFetching }: CheckboxProps) => {
  return (
    <label
      className={`flex items-center gap-3 ${
        disabled ? 'cursor-default' : 'cursor-pointer'
      } group w-fit`}
      aria-checked={value}
    >
      <div className="flex items-center justify-center">
        <input
          disabled={disabled}
          checked={value}
          className={`peer/checkbox main-transition-colors ${
            disabled
              ? 'cursor-default border-darkgray/20 bg-gray-600/40 checked:border-darkblue/75 checked:bg-darkblue/60'
              : 'border-darkgray checked:border-darkblue checked:bg-darkblue'
          } ${
            isFetching && 'animate-pulse'
          } h-[20px] w-[20px] cursor-pointer appearance-none rounded-md border outline-none ring-blue ring-offset-1 focus-visible:ring-2`}
          type="checkbox"
          onChange={e => onChange(e.target.checked)}
        />
        <CheckCheckboxIcon
          className={`absolute ${
            disabled
              ? value
                ? 'fill-white/70 opacity-100'
                : 'opacity-0'
              : 'fill-white opacity-100'
          }`}
        />
      </div>
      {label && (
        <span
          className={`main-transition-colors ${
            value ? 'text-darkblue' : 'text-darkblue/50'
          } leading-5`}
        >
          {label}
        </span>
      )}
    </label>
  )
}
