import { twMerge } from 'tailwind-merge'
import {
  AttachmentUploadStatusEnum,
  PostAttachmentInterface,
} from 'modules/community/types/post-attachment'

interface EmbeddedVideoPreviewProps {
  className?: string
  attachment: PostAttachmentInterface
}

const EmbeddedVideoPreview = ({ attachment, className }: EmbeddedVideoPreviewProps) => {
  return (
    <div
      className={twMerge(
        'h-40 max-w-36',
        attachment.status === AttachmentUploadStatusEnum.Uploading && 'w-36 bg-slate-100',
        className,
      )}
    >
      <img
        className="h-full rounded-lg object-cover"
        src={attachment.previewUrl ?? undefined}
        alt={attachment.previewUrl ?? undefined}
      />
    </div>
  )
}

export default EmbeddedVideoPreview
