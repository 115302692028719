import { twMerge } from 'tailwind-merge'
import {
  AttachmentUploadStatusEnum,
  VideoUploadAttachment,
} from 'modules/community/types/post-attachment'

interface VideoAttachmentPreviewProps {
  className?: string
  attachment: VideoUploadAttachment
}

const VideoAttachmentPreview = ({ attachment, className }: VideoAttachmentPreviewProps) => {
  return (
    <video
      className={twMerge(
        'h-40 max-w-36 rounded-lg object-cover',
        attachment.status === AttachmentUploadStatusEnum.Uploading && 'bg-slate-100',
        className,
      )}
      key={attachment.id}
      src={attachment.url}
    />
  )
}

export default VideoAttachmentPreview
