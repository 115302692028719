import { MouseEventHandler } from 'react'
import { useTranslation } from 'next-i18next'
import FullscreenIcon from 'shared/icons/fullscreen-icon'
import { usePostAttachmentsContext } from 'modules/attachments/components/context'

interface FullscreenGalleryButtonProps {
  id: string | number
  className?: string
}

const FullscreenGalleryButton = ({ id, className }: FullscreenGalleryButtonProps) => {
  const { t } = useTranslation()
  const { attachments, setInitialGallerySlide, setIsGalleryVisible } = usePostAttachmentsContext()

  const handleOpenGallery: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation()

    setInitialGallerySlide(attachments?.findIndex(attachment => attachment.id === id))
    setIsGalleryVisible(true)
  }

  return (
    <button
      type="button"
      className={className}
      onClick={handleOpenGallery}
      aria-label={t('post.attachments.open_gallery')}
    >
      <FullscreenIcon className="stroke-white" />
    </button>
  )
}

export default FullscreenGalleryButton
