import axios, { AxiosResponse } from 'axios'
import {
  PostAttachmentType,
  PostAttachmentsApiCreateInterface,
} from 'modules/community/types/post-attachment'
import { LikeInterface } from '../components/Likes/Likes.types'
import { CommentInterface, Suggestion } from '../components/comment/comment.types'
import { CommentsTypeEnum } from '../types'
import { commentSourceKeyMap } from '../utils/constants'
import { baseFetcher } from './http-client'
import { AddCommentRequestType, ApiPaginationResponseInterface } from './types'

export function addComment<Type extends CommentsTypeEnum>(body: AddCommentRequestType<Type>) {
  return axios.post('/api/comment/comments', body)
}

export const editComment = (
  id: number,
  body: { text: string; attachments?: PostAttachmentsApiCreateInterface[] },
) => {
  return axios.put<any, AxiosResponse<CommentInterface>>(`/api/comment/comments/${id}`, {
    text: body.text,
    attachments: body.attachments,
  })
}

export function getPageCommentsByLastId(
  parentId: number | null,
  commentsType: CommentsTypeEnum,
  pageId: number,
  lastCommentId: number,
): Promise<ApiPaginationResponseInterface<CommentInterface>> {
  const commentSourceKey = commentSourceKeyMap[commentsType]

  if (lastCommentId === 0) {
    return baseFetcher(
      `/api/comment/comments?pagination[limit]=10&pagination[order]=next&parent=${parentId}&${commentSourceKey}=${pageId}`,
    )
  }

  return baseFetcher(
    `/api/comment/comments?pagination[startFrom]=${lastCommentId}&pagination[order]=next&pagination[limit]=10&parent=${parentId}&${commentSourceKey}=${pageId}`,
  )
}

export function deleteComment(commentId: number) {
  return axios.delete(`/api/comment/comments/${commentId}`)
}

export function likeComment(commentId: number) {
  return axios.post<CommentInterface>(`/api/comment/comments/${commentId}/like`)
}

export function unlikeComment(commentId: number) {
  return axios.delete(`/api/comment/comments/${commentId}/like`)
}

export function getLastLikes(commentId: number): Promise<LikeInterface[]> {
  return baseFetcher(`/api/comment/comments/${commentId}/last_likes`)
}

export function getSuggestionsList(
  path: string,
  postId: number,
  displayNameSearchBy?: string,
): Promise<Suggestion[]> {
  const params = new URLSearchParams({
    postId: postId.toString(),
  })
  if (displayNameSearchBy) {
    params.append('displayNameSearchBy', displayNameSearchBy)
  }
  return baseFetcher(
    `/api/community/community/${path}/get-members-for-mention?${params.toString()}`,
  )
}
