export const UserInfoPopoverSkeleton = () => (
  <div className="gap-15 flex h-full w-full flex-col items-start gap-10 p-[30px] sm:min-h-[250px] sm:w-[500px] sm:flex-row">
    <div className="h-40 min-h-[160px] w-40 min-w-[160px] rounded-full bg-gray" />
    <div className="flex w-full flex-grow flex-col gap-3">
      <div className="flex w-full flex-row gap-2">
        <div className="h-9 basis-1/2 rounded-sm bg-gray" />
        <div className="h-9 basis-1/2 rounded-sm bg-gray" />
      </div>
      <div className="h-5 rounded-sm bg-gray" />
      <div className="h-5 rounded-sm bg-gray" />
      <div className="h-5 rounded-sm bg-gray" />
    </div>
  </div>
)
