import React from 'react'

function CloseThinIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path d="M1.22093 25C0.979466 25 0.743411 24.9285 0.542626 24.7943C0.34184 24.6602 0.185345 24.4695 0.0929338 24.2465C0.000522908 24.0234 -0.0236515 23.7779 0.0234685 23.5411C0.0705884 23.3042 0.186886 23.0867 0.35765 22.916L22.9162 0.357407C23.1452 0.128451 23.4557 -0.000175476 23.7795 -0.000175476C24.1033 -0.000175476 24.4138 0.128451 24.6428 0.357407C24.8718 0.586364 25.0004 0.896895 25.0004 1.22069C25.0004 1.54448 24.8718 1.85501 24.6428 2.08397L2.08421 24.6426C1.97095 24.7561 1.83638 24.8461 1.68823 24.9074C1.54008 24.9687 1.38128 25.0002 1.22093 25Z" />
        <path d="M23.7795 25C23.6191 25.0002 23.4603 24.9687 23.3122 24.9074C23.164 24.8461 23.0294 24.7561 22.9162 24.6426L0.357583 2.08397C0.128626 1.85501 0 1.54448 0 1.22069C0 0.896895 0.128626 0.586364 0.357583 0.357407C0.586539 0.128451 0.897071 -0.000175476 1.22086 -0.000175476C1.54466 -0.000175476 1.85519 0.128451 2.08415 0.357407L24.6427 22.916C24.8135 23.0867 24.9298 23.3042 24.9769 23.5411C25.024 23.7779 24.9999 24.0234 24.9075 24.2465C24.815 24.4695 24.6585 24.6602 24.4578 24.7943C24.257 24.9285 24.0209 25 23.7795 25Z" />
      </g>
    </svg>
  )
}

export default CloseThinIcon
