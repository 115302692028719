import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'next-i18next'
import FormInputSearch from 'shared/components/form-input-search'
import { Loader } from 'shared/components/loader'
import { useDebouncedValue } from 'shared/hooks/use-debounced-value'
import useOnScreen from 'shared/hooks/use-on-screen'
import { GIFObject } from 'modules/attachments/api/tenor.types'
import { useGifs } from 'modules/attachments/api/use-gifs'

interface GifPickerPanelProps {
  onSelect: (gif: GIFObject) => void
  onClose: () => void
}

export const GifPickerPanel: FC<GifPickerPanelProps> = ({ onSelect, onClose }) => {
  const { t } = useTranslation()

  const [query, setQuery] = useState('')
  const [debouncedQuery] = useDebouncedValue(query, 300)
  const { gifs, error, isLoadingMore, isReachingEnd, loadMore } = useGifs(debouncedQuery, 16)

  const ref = useRef<HTMLDivElement>(null)
  const isVisible = useOnScreen(ref)

  useEffect(() => {
    if (isVisible && !isReachingEnd && !isLoadingMore) {
      loadMore()
    }
  }, [isVisible, isReachingEnd, isLoadingMore])

  const handleQueryChange = useCallback((value: string) => {
    setQuery(value)
  }, [])

  const handleGifSelect = (gif: GIFObject) => {
    onSelect(gif)
    onClose()
  }

  return (
    <div className="relative z-10 flex max-h-[875px] w-72 flex-col overflow-auto rounded-xl bg-white shadow-2xl">
      <div className="h-96 w-72 max-w-full overflow-auto">
        <div className="flex w-full flex-col items-center p-4 pb-11">
          <FormInputSearch
            className="border-1 relative mb-4 flex h-10 w-full items-center justify-start rounded-md border border-gray-200 focus-within:border-blue"
            inputClassName="h-full w-full border-none bg-transparent outline-none"
            placeholder={t('global.search')}
            value={query}
            onChange={e => handleQueryChange(e.target.value)}
            onClear={() => handleQueryChange('')}
          />

          <div className="flex w-full flex-wrap justify-between">
            {gifs.map(gif => (
              <div
                key={gif.id}
                className="mb-2 h-[77px] w-[calc(50%-4px)] animate-loadingGifPulse cursor-pointer overflow-hidden rounded bg-[length:400px]"
                onClick={() => handleGifSelect(gif)}
              >
                <div
                  className="h-full w-full bg-cover bg-center"
                  style={{ backgroundImage: `url(${gif.tinyUrl})` }}
                />
              </div>
            ))}
          </div>

          <div ref={ref} className="w-full" />

          {isLoadingMore && (
            <div className="flex items-center justify-center p-5">
              <Loader type="light" className="fill-transparent" />.
            </div>
          )}
          {isReachingEnd && gifs.length > 0 && (
            <div className="py-4 text-sm text-gray-300">{t('gifs.panel.end_of_results')}</div>
          )}
          {gifs.length === 0 && !isLoadingMore && !error && (
            <div className="py-4 text-sm text-gray-300">{t('gifs.panel.nothing_found')}</div>
          )}
        </div>
        <div className="absolute bottom-0 left-0 flex h-11 w-full items-center bg-white" />
      </div>
    </div>
  )
}
