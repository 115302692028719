import {
  PropsWithChildren,
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import { AttachmentGallery } from 'modules/community/components/attachment-gallery/attachment-gallery'
import {
  AttachmentUploadStatusEnum,
  PostAttachmentType,
} from 'modules/community/types/post-attachment'

interface PostAttachmentsContextInterface {
  attachments?: PostAttachmentType[]
  initialGallerySlide?: number
  isGalleryVisible: boolean
  isEditable: boolean
  isUploading: boolean
  setIsGalleryVisible: (isGalleryVisible: boolean, initialSlide?: number) => void
  setInitialGallerySlide: (initialGallerySlide?: number) => void
  addAttachment: (attachment: PostAttachmentType) => number
  removeAttachment: (id: string | number) => void
  addError: (id: number, error: string) => void
  updateAttachment: (id: number, attachment: Partial<PostAttachmentType>) => void
  reorder: (activeId: number, targetId: number) => void
}

const PostAttachmentsContext = createContext<PostAttachmentsContextInterface>({
  attachments: [],
  isGalleryVisible: false,
  isEditable: false,
  isUploading: false,
  setInitialGallerySlide: () => {},
  setIsGalleryVisible: () => {},
  addAttachment: () => 0,
  removeAttachment: () => {},
  addError: () => {},
  updateAttachment: () => {},
  reorder: () => {},
})

export const usePostAttachmentsContext = () => useContext(PostAttachmentsContext)

interface PostAttachmentsProviderProps extends PropsWithChildren {
  attachments?: PostAttachmentType[]
  isEditable?: boolean
  onChange?: (attachments: PostAttachmentType[]) => void
}

export interface AttachmentsRefInterface {
  clear: () => void
}

export const PostAttachmentsProvider = forwardRef<
  AttachmentsRefInterface,
  PostAttachmentsProviderProps
>(function PostAttachmentsProvider(props, ref) {
  const [initialGallerySlide, setInitialGallerySlide] = useState<number>()
  const [isGalleryVisible, setIsGalleryVisible] = useState<boolean>(false)
  const [attachments, setAttachments] = useState<PostAttachmentType[]>(props.attachments ?? [])

  const addAttachment = (attachment: PostAttachmentType) => {
    const newIndex = attachments.length
    setAttachments(prev => prev.concat(attachment))
    return newIndex
  }

  const isUploading = useMemo(
    () => attachments.some(({ status }) => status === AttachmentUploadStatusEnum.Uploading),
    [attachments],
  )

  const removeAttachment = useCallback(
    (id: string | number) => setAttachments(prev => prev.filter(a => a.id !== id)),
    [],
  )

  const addError = useCallback(
    (index: number, error: string) =>
      setAttachments(prev =>
        prev.map((attachment, i) =>
          i === index
            ? { ...attachment, error, status: AttachmentUploadStatusEnum.Failed }
            : attachment,
        ),
      ),
    [],
  )

  const updateAttachment = useCallback(
    (index: number, attachment: Partial<PostAttachmentType>) =>
      //@ts-expect-error @TODO intermediate "updating" type
      setAttachments(prev => prev.map((a, i) => (i === index ? { ...a, ...attachment } : a))),
    [],
  )

  const reorder = useCallback(
    (activeId: number, targetId: number) =>
      setAttachments(prev => {
        const activeIndex = prev.findIndex(a => a.id === activeId)
        const targetIndex = prev.findIndex(a => a.id === targetId)
        const copy = prev.slice()
        const element = copy.splice(activeIndex, 1)[0]
        copy.splice(targetIndex, 0, element)
        return copy
      }),
    [],
  )

  useImperativeHandle(ref, () => ({
    clear: () => setAttachments([]),
  }))

  useEffect(() => {
    if (props.onChange) {
      props.onChange(attachments)
    }
  }, [attachments, props.onChange])

  return (
    <PostAttachmentsContext.Provider
      value={{
        attachments,
        isGalleryVisible,
        initialGallerySlide,
        isUploading,
        isEditable: props.isEditable ?? false,
        setIsGalleryVisible,
        setInitialGallerySlide,
        addAttachment,
        removeAttachment,
        addError,
        updateAttachment,
        reorder,
      }}
    >
      {props.children}
      {isGalleryVisible && <AttachmentGallery />}
    </PostAttachmentsContext.Provider>
  )
})
