import { VideoUploadAttachment } from 'modules/community/types/post-attachment'

export interface VideoGalleryPreviewProps {
  attachment: VideoUploadAttachment
}

const VideoGalleryPreview = ({ attachment }: VideoGalleryPreviewProps) => (
  <article
    key={attachment.id}
    className="inline-flex w-full flex-shrink-0 snap-center justify-center px-20 py-10 lg:px-80 lg:py-40"
  >
    <video controls src={attachment.url} />
  </article>
)

export default VideoGalleryPreview
