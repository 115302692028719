export enum MemberRoleEnum {
  Member = 'ROLE_MEMBER',
  Owner = 'ROLE_OWNER',
  Admin = 'ROLE_ADMIN',
  Moderator = 'ROLE_ASSISTANT',
}

export function getReadableMemberRole(role: MemberRoleEnum) {
  switch (role) {
    case MemberRoleEnum.Member:
      return 'member.role.member.title'
    case MemberRoleEnum.Owner:
      return 'member.role.owner.title'
    case MemberRoleEnum.Admin:
      return 'member.role.admin.title'
    case MemberRoleEnum.Moderator:
      return 'member.role.moderator.title'
    default:
      return ''
  }
}

export enum MemberWorkspaceRoleEnum {
  Owner = 'role_owner',
  Admin = 'role_admin',
  Moderator = 'role_assistant',
}

export function getReadableMemberWorkspaceRole(workspaceRole: MemberWorkspaceRoleEnum) {
  switch (workspaceRole) {
    case MemberWorkspaceRoleEnum.Owner:
      return 'member.workspace_role.owner.title'
    case MemberWorkspaceRoleEnum.Admin:
      return 'member.workspace_role.admin.title'
    case MemberWorkspaceRoleEnum.Moderator:
      return 'member.workspace_role.moderator.title'
    default:
      return ''
  }
}

export enum BanPeriodUnitEnum {
  day = 'day',
}
