import ReactPlayer from 'react-player'
import { oembed as loomOembed, validate } from '@loomhq/loom-embed'
import { NOEMBED_BASE_URL } from 'modules/attachments/const/attachments-consts'
import { OEmbedInterface } from 'modules/attachments/types/attachment-types'
import { baseFetcher } from 'modules/comments/api/http-client'

export const isValidEmbeddedVideoUrl = (url: string) =>
  validate.isLoomUrl(url) || ReactPlayer.canPlay(url)

export const fetchOembed = (url: string) => {
  const isLoomUrl = validate.isLoomUrl(url)

  if (isLoomUrl) {
    return loomOembed(url)
  }

  return baseFetcher<OEmbedInterface>(`${NOEMBED_BASE_URL}?url=${url}`)
}
