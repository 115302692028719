import React, { Fragment, useState } from 'react'
import { usePopper } from 'react-popper'
import { ReactEditor, useSlate } from 'slate-react'
import { Emoji } from 'emoji-mart'
import { twMerge } from 'tailwind-merge'
import EmojiOutlineIcon from 'shared/icons/emoji-outline-icon'
import { fileInputAcceptTypes } from 'shared/utils/file-input-accept-types'
import EmbeddedVideoAttachment from 'modules/attachments/components/embedded-video-attachment'
import { GifAttachment } from 'modules/attachments/components/gif-attachment'
import UploadAttachment from 'modules/attachments/components/upload-attachment'
import { toolbarGroups } from '../../../utils/constants'
import { ToolbarButton } from '../common/toolbar-button'
import LinkButton from '../elements/Link/LinkButton'
import { LinkModal } from '../elements/Link/link-modal'
import MentionButton from '../elements/Mention/MentionButton'
import EmojiPicker from '../elements/emoji-picker/emoji-picker'
import { insertText } from '../utils/serialize'

export interface ToolbarProps {
  className?: string
  path?: string
}

export const Toolbar = ({ path, className = '' }: ToolbarProps) => {
  const editor = useSlate()
  const [linkIsEditing, setLinkIsEditing] = useState(false)
  const [emojiPickerVisible, setEmojiPickerVisible] = useState(false)

  const [emojiReferenceElement, setEmojiReferenceElement] = useState<HTMLElement | null>(null)
  const [emojiPopperElement, setEmojiPopperElement] = useState<HTMLDivElement | null>(null)
  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    emojiReferenceElement,
    emojiPopperElement,
    {
      placement: 'top',
      strategy: 'fixed',
      modifiers: [
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['bottom'],
          },
        },
      ],
    },
  )

  const handleMentionClick = () => {
    insertText(editor, '@')
    ReactEditor.focus(editor) // Focus the editor after inserting the mention
  }

  const handleAddEmoji = (emoji: Emoji) => {
    setEmojiPickerVisible(false)
    // @ts-ignore
    insertText(editor, emoji.native as string)
  }

  return (
    <div className={twMerge('flex items-center gap-3', className)}>
      <UploadAttachment
        className="group"
        iconClassName="h-5 w-5 text-lightgray group-hover:text-darkblue"
      />
      <EmbeddedVideoAttachment />
      <GifAttachment />
      {!linkIsEditing && (
        <>
          {toolbarGroups.map(element => {
            switch (element.type) {
              case 'link':
                return <LinkButton key={element.id} setLinkIsEditing={setLinkIsEditing} />
              case 'mention':
                return path && <MentionButton key={element.id} onClick={handleMentionClick} />
              default:
                return <div />
            }
          })}
          <ToolbarButton ref={setEmojiReferenceElement}>
            <EmojiOutlineIcon
              className="cursor-pointer"
              onClick={() => setEmojiPickerVisible(prev => !prev)}
            />
            <div
              ref={setEmojiPopperElement}
              style={{ ...popperStyles.popper, zIndex: 10 }}
              {...popperAttributes.popper}
              // className={commentEditorStyles.CommentEmojiContent}
            >
              {emojiPickerVisible && (
                <EmojiPicker
                  setEmojiPickerVisible={setEmojiPickerVisible}
                  onEmojiSelect={handleAddEmoji}
                />
              )}
            </div>
          </ToolbarButton>
        </>
      )}
      <LinkModal setLinkIsEditing={setLinkIsEditing} linkIsEditing={linkIsEditing} />
    </div>
  )
}
