export default function ClipIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 25" fill="none" {...props}>
      <path
        d="M23.702 9.1L10.997 21.664a5.434 5.434 0 01-7.624 0 5.29 5.29 0 010-7.54L14.808 2.818a3.623 3.623 0 015.082 0 3.526 3.526 0 010 5.026L8.455 19.151a1.81 1.81 0 01-2.542 0 1.764 1.764 0 010-2.513L16.078 6.587l-1.27-1.257L4.643 15.382a3.526 3.526 0 000 5.026 3.623 3.623 0 005.082 0L21.16 9.101a5.29 5.29 0 000-7.54 5.434 5.434 0 00-7.624 0L1.467 13.499l.043.043a7.046 7.046 0 00.592 9.38c2.592 2.563 6.665 2.754 9.485.585l.044.044 13.34-13.193L23.703 9.1z"
        fill="currentColor"
      />
    </svg>
  )
}
