import { validate } from '@loomhq/loom-embed'
import EmbeddedLoomGalleryPreview from 'modules/community/components/attachment-gallery/embedded-video-preview/embedded-loom-gallery-preview'
import EmbeddedVideoGalleryPreview from 'modules/community/components/attachment-gallery/embedded-video-preview/embedded-video-gallery-preview'
import { GifGalleryPreview } from 'modules/community/components/attachment-gallery/gif-gallery-preview'
import ImageGalleryPreview from 'modules/community/components/attachment-gallery/image-gallery-preview'
import VideoGalleryPreview from 'modules/community/components/attachment-gallery/video-gallery-preview'
import { AttachmentTypeEnum, PostAttachmentType } from 'modules/community/types/post-attachment'

export const galleryFactory = (attachment: PostAttachmentType) => {
  switch (attachment.type) {
    case AttachmentTypeEnum.Image:
    case AttachmentTypeEnum.EmbeddedImage:
      return <ImageGalleryPreview key={attachment.id} attachment={attachment} />
    case AttachmentTypeEnum.Video:
      return <VideoGalleryPreview key={attachment.id} attachment={attachment} />
    case AttachmentTypeEnum.EmbeddedVideo:
      if (validate.isLoomUrl(attachment.url)) {
        return <EmbeddedLoomGalleryPreview key={attachment.id} attachment={attachment} />
      }

      return <EmbeddedVideoGalleryPreview key={attachment.id} attachment={attachment} />
    case AttachmentTypeEnum.EmbeddedGif:
      return <GifGalleryPreview key={attachment.id} attachment={attachment} />
    default:
      return null
  }
}
