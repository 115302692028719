import { GifAttachment } from 'modules/community/types/post-attachment'

export interface GifGalleryPreviewProps {
  attachment: GifAttachment
}

export const GifGalleryPreview = ({ attachment }: GifGalleryPreviewProps) => (
  <article
    key={attachment.id}
    className="inline-flex w-full flex-shrink-0 snap-center justify-center px-20 py-10 lg:px-80 lg:py-40"
  >
    <img className="object-contain" src={attachment.url} alt={attachment.url} />
  </article>
)
