import { ComponentProps, PropsWithChildren } from 'react'
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers'
import { SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import { twMerge } from 'tailwind-merge'
import Sortable from 'shared/components/sortable'
import { usePostAttachmentsContext } from '../context'
import AttachmentPreviewItem from './preview-item'

const Wrapper = ({
  children,
  attachment,
}: PropsWithChildren & ComponentProps<typeof AttachmentPreviewItem>) => {
  if (!attachment.id) {
    return <li>{children}</li>
  }

  return (
    <Sortable id={attachment.id} data={attachment}>
      {children}
    </Sortable>
  )
}

interface AttachmentsPreviewProps {
  className?: string
}

export default function AttachmentsPreview({ className }: AttachmentsPreviewProps) {
  const { attachments, reorder } = usePostAttachmentsContext()

  const attachmentIds = attachments?.filter(a => !!a.id).map(a => a.id) ?? []

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    reorder(active.id as number, over?.id as number)
  }

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 5 } }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  return (
    <ul className={twMerge('customScroll flex max-w-full gap-3 overflow-x-auto', className)}>
      <DndContext
        onDragEnd={handleDragEnd}
        sensors={sensors}
        modifiers={[restrictToHorizontalAxis]}
      >
        <SortableContext items={attachmentIds}>
          {attachments?.map((attachment, i) => (
            <Wrapper key={attachment.id} attachment={attachment}>
              <AttachmentPreviewItem attachment={attachment} />
            </Wrapper>
          ))}
        </SortableContext>
      </DndContext>
    </ul>
  )
}
