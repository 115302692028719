import { FC, useState } from 'react'
import { usePopper } from 'react-popper'
import { twMerge } from 'tailwind-merge'
import { useClickOutside } from 'shared/hooks/use-click-outside'
import { GIFObject } from 'modules/attachments/api/tenor.types'
import { GifPickerButton } from './gif-picker-button'
import { GifPickerPanel } from './gif-picker-panel'

interface GifPickerProps {
  className?: string
  onSelect: (gif: GIFObject) => void
}

export const GifPicker: FC<GifPickerProps> = ({ onSelect, className }) => {
  const [isOpen, setIsOpen] = useState(false)

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'flip',
        enabled: true,
        options: {
          fallbackPlacements: ['top', 'bottom'],
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  })

  const ref = useClickOutside(() => setIsOpen(false))

  const togglePicker = () => {
    setIsOpen(prev => !prev)
  }

  const closePicker = () => {
    setIsOpen(false)
  }

  return (
    <div ref={ref} className={twMerge('flex', className)}>
      <GifPickerButton
        setReferenceElement={setReferenceElement}
        isOpen={isOpen}
        onToggle={togglePicker}
      />
      {isOpen && (
        <div ref={setPopperElement} style={{ ...styles.popper, zIndex: 30 }} {...attributes.popper}>
          <GifPickerPanel onSelect={onSelect} onClose={closePicker} />
        </div>
      )}
    </div>
  )
}
