import { CommentsTypeEnum } from '../types'

export const commentSourceKeyMap: Record<CommentsTypeEnum, string> = {
  [CommentsTypeEnum.Page]: 'commentSourcePage.pageId',
  [CommentsTypeEnum.Community]: 'commentSourceCommunityPost.communityPostId',
}

export const toolbarGroups = [
  {
    id: 1,
    format: 'link',
    type: 'link',
  },
  {
    id: 2,
    format: 'mention',
    type: 'mention',
  },
]
