import { useEffect, useState } from 'react'
import useSWR from 'swr'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { GIFObject, GifSearchResponse } from './tenor.types'

export function useGifs(query: string, limit = 16) {
  const trimmedQuery = query.trim()

  const [gifs, setGifs] = useState<GIFObject[]>([])
  const [pos, setPos] = useState<string | null>(null)
  const [nextPos, setNextPos] = useState<string | null>(null)
  const [isReachingEnd, setIsReachingEnd] = useState(false)

  useEffect(() => {
    setGifs([])
    setPos(null)
    setNextPos(null)
    setIsReachingEnd(false)
  }, [query])

  const { fetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.get, GifSearchResponse>({
    method: RequestMethodsEnum.get,
  })

  const queryParams = new URLSearchParams()
  if (pos) {
    queryParams.set('pos', pos)
  }
  if (limit) {
    queryParams.set('limit', limit.toString())
  }
  if (trimmedQuery) {
    queryParams.set('query', trimmedQuery)
  }

  const { data, error } = useSWR<GifSearchResponse>(
    `/api/google-integration/gifs?${queryParams.toString()}`,
    fetcher,
  )

  useEffect(() => {
    if (data) {
      setGifs(prevGifs => [...prevGifs, ...data.results])
      setNextPos(data.next || null)
      if (!data.next) {
        setIsReachingEnd(true)
      }
    }
  }, [data])

  const isLoadingMore = !data && !error

  const loadMore = () => {
    if (!isReachingEnd && !isLoadingMore) {
      setPos(nextPos)
    }
  }

  return {
    gifs,
    error,
    isLoadingMore,
    isReachingEnd,
    loadMore,
  }
}
