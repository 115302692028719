import {
  AttachmentTypeEnum,
  AttachmentUploadStatusEnum,
} from 'modules/community/types/post-attachment'
import { GIFObject } from '../api/tenor.types'
import { usePostAttachmentsContext } from './context'
import { GifPicker } from './gif-picker'

export interface GifAttachmentProps {
  className?: string
}

export const GifAttachment = ({ className }: GifAttachmentProps) => {
  const { addAttachment } = usePostAttachmentsContext()

  const handleGif = (gif: GIFObject) => {
    addAttachment({
      id: crypto.randomUUID(),
      status: AttachmentUploadStatusEnum.Success,
      url: gif.url,
      previewUrl: gif.previewUrl,
      type: AttachmentTypeEnum.EmbeddedGif,
    })
  }

  return <GifPicker className={className} onSelect={handleGif} />
}
