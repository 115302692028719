import { ComponentProps } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'next-i18next'
import PlainUploader from 'shared/components/plain-uploader'
import { fileInputAcceptTypes } from 'shared/utils/file-input-accept-types'
import { usePostAttachmentsContext } from 'modules/attachments/components/context'
import useUploadFile from 'modules/attachments/hooks/use-upload-datafile'
import {
  AttachmentTypeEnum,
  AttachmentUploadStatusEnum,
} from 'modules/community/types/post-attachment'

interface UploadAttachmentProps extends ComponentProps<'input'> {
  className?: string
  iconClassName?: string
  acceptTypes?: string[]
}

const IMAGE_SIZE_LIMIT_MB = 15
const IMAGE_SIZE_LIMIT_BYTES = IMAGE_SIZE_LIMIT_MB * Math.pow(10, 6)

const defaultAcceptTypes = fileInputAcceptTypes.imageExtensions.concat(
  fileInputAcceptTypes.videoExtension,
)

const UploadAttachment = ({
  className,
  iconClassName,
  acceptTypes = defaultAcceptTypes,
}: UploadAttachmentProps) => {
  const { t } = useTranslation()
  const { addError, addAttachment, updateAttachment } = usePostAttachmentsContext()
  const { validateAndGetUploadOptions, uploadFile, activateFile } = useUploadFile()

  const handleVideoUpload = async (file: File) => {
    const attachmentId = addAttachment({
      id: crypto.randomUUID(),
      status: AttachmentUploadStatusEnum.Uploading,
      type: AttachmentTypeEnum.Video,
    })

    try {
      const { id, uploadOptions } = await validateAndGetUploadOptions({
        file,
      })

      await uploadFile({ file, uploadOptions })
      const activatedFile = await activateFile(id)

      updateAttachment(attachmentId, {
        dataFileId: id,
        status: AttachmentUploadStatusEnum.Success,
        url: activatedFile[id].path,
      })
    } catch {
      addError(attachmentId, t('post.attachments.upload_failed'))
    }
  }

  const handleImageUpload = async (file: File) => {
    if (file.size > IMAGE_SIZE_LIMIT_BYTES) {
      toast.error(
        t('post.attachments.file_upload.size_limit_reached', { size: `${IMAGE_SIZE_LIMIT_MB}MB` }),
      )
      return
    }

    const attachmentId = addAttachment({
      id: crypto.randomUUID(),
      status: AttachmentUploadStatusEnum.Uploading,
      type: AttachmentTypeEnum.Image,
    })

    try {
      const { id, uploadOptions } = await validateAndGetUploadOptions({
        file,
      })
      await uploadFile({ file, uploadOptions })
      const activatedFile = await activateFile(id)

      updateAttachment(attachmentId, {
        dataFileId: id,
        status: AttachmentUploadStatusEnum.Success,
        url: activatedFile[id].path,
      })
    } catch {
      addError(attachmentId, t('post.attachments.upload_failed'))
    }
  }

  const handleSelectFile = async (file: File) => {
    if (fileInputAcceptTypes.videoExtension.includes(file.type)) {
      handleVideoUpload(file)
    } else if (fileInputAcceptTypes.imageExtensions.includes(file.type)) {
      handleImageUpload(file)
    } else {
      toast.error(t('post.attachments.file_upload.unsupported_type'))
    }
  }

  return (
    <PlainUploader
      className={className}
      iconClassName={iconClassName}
      onSelect={handleSelectFile}
      onError={error => toast.error(error)}
      acceptTypes={acceptTypes}
    />
  )
}

export default UploadAttachment
