export const fileInputAcceptTypes = {
  image: ['image/*'],
  video: ['video/*'],
  videoExtension: ['video/x-msvideo', 'video/mp4', 'video/mpeg', 'video/ogg', 'video/webm'],
  imageExtensions: ['image/jpeg', 'image/png', 'image/webp'],
  all: [
    'image/*',
    'video/*',
    'audio/*',
    'application/*',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'application/vnd.apple.numbers',
    'application/pdf',
    'text/*',
  ],
}

export const isAcceptTypeValid = (fileAcceptType: string, fileInputAcceptTypes: string[]) =>
  fileInputAcceptTypes.some(fileInputAcceptType => RegExp(fileInputAcceptType).test(fileAcceptType))
