import EmbeddedVideoPreview from 'modules/attachments/components/preview/embedded-video-preview'
import VideoAttachmentPreview from 'modules/attachments/components/preview/video-preview'
import { AttachmentTypeEnum, PostAttachmentType } from 'modules/community/types/post-attachment'
import { GifAttachmentPreview } from './gif-preview'
import ImageAttachmentPreview from './image-preview'

export const previewFactory = (
  attachment: PostAttachmentType,
  className?: string,
  size?: 'small' | 'original',
) => {
  switch (attachment.type) {
    case AttachmentTypeEnum.Image:
    case AttachmentTypeEnum.EmbeddedImage:
      return <ImageAttachmentPreview className={className} attachment={attachment} size={size} />
    case AttachmentTypeEnum.EmbeddedVideo:
      return <EmbeddedVideoPreview className={className} attachment={attachment} />
    case AttachmentTypeEnum.Video:
      return <VideoAttachmentPreview className={className} attachment={attachment} />
    case AttachmentTypeEnum.EmbeddedGif:
      return (
        <GifAttachmentPreview attachment={attachment} key={attachment.id} className={className} />
      )
    default:
      return null
  }
}
