import React from 'react'

function GIFIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4817 25H7.46169C6.47399 25 5.68847 25 5.05469 24.9482C4.40573 24.8952 3.85233 24.7843 3.34561 24.5261C2.52753 24.1093 1.86241 23.4441 1.44557 22.6261C1.18737 22.1194 1.07647 21.566 1.02344 20.917C0.971645 20.2832 0.97168 19.4977 0.97168 18.5101V6.48995C0.97168 5.50228 0.971645 4.71675 1.02344 4.08301C1.07647 3.43405 1.18737 2.88065 1.44557 2.37393C1.86241 1.55585 2.52753 0.89073 3.34561 0.473888C3.85233 0.215694 4.40573 0.104794 5.05469 0.0517623C5.68843 -3.43388e-05 6.47396 0 7.46163 0H19.4817C20.4694 0 21.2549 -3.43388e-05 21.8887 0.0517623C22.5376 0.104794 23.091 0.215694 23.5977 0.473888C24.4158 0.89073 25.0809 1.55585 25.4978 2.37393C25.756 2.88065 25.8669 3.43405 25.9199 4.08301C25.9717 4.71679 25.9717 5.50231 25.9717 6.49001V18.51C25.9717 19.4977 25.9717 20.2832 25.9199 20.917C25.8669 21.566 25.756 22.1194 25.4978 22.6261C25.0809 23.4441 24.4158 24.1093 23.5977 24.5261C23.091 24.7843 22.5376 24.8952 21.8887 24.9482C21.2549 25 20.4694 25 19.4817 25ZM21.7706 23.5038C22.33 23.4581 22.6714 23.3715 22.9398 23.2348C23.4852 22.9569 23.9286 22.5135 24.2065 21.9681C24.3432 21.6998 24.4298 21.3583 24.4754 20.799C24.5219 20.2311 24.5224 19.5048 24.5224 18.4783V6.52174C24.5224 5.49518 24.5219 4.76886 24.4754 4.20105C24.4298 3.64168 24.3432 3.30023 24.2065 3.03192C23.9286 2.48651 23.4852 2.04309 22.9398 1.7652C22.6714 1.62847 22.33 1.54193 21.7706 1.49624C21.2028 1.44982 20.4765 1.44928 19.4499 1.44928H7.49342C6.46686 1.44928 5.74054 1.44982 5.17273 1.49624C4.61336 1.54193 4.27191 1.62847 4.0036 1.7652C3.45819 2.04309 3.01477 2.48651 2.73688 3.03192C2.60015 3.30023 2.51361 3.64168 2.46792 4.20105C2.4215 4.76886 2.42096 5.49518 2.42096 6.52174V18.4783C2.42096 19.5048 2.4215 20.2311 2.46792 20.799C2.51361 21.3583 2.60015 21.6998 2.73688 21.9681C3.01477 22.5135 3.45819 22.9569 4.0036 23.2348C4.27191 23.3715 4.61336 23.4581 5.17273 23.5038C5.74054 23.5502 6.46686 23.5507 7.49342 23.5507H19.4499C20.4765 23.5507 21.2028 23.5502 21.7706 23.5038Z"
        fill="currentColor"
      />
      <path
        d="M9.3459 16.2287C8.58535 16.2287 7.93985 16.0849 7.40939 15.7973C6.88532 15.5097 6.48587 15.107 6.21104 14.5893C5.93621 14.0717 5.79883 13.4645 5.79883 12.7679C5.79883 12.231 5.87872 11.7485 6.03846 11.3203C6.20466 10.8857 6.44114 10.5182 6.74791 10.2178C7.06105 9.91104 7.43814 9.67456 7.87913 9.5084C8.3265 9.34224 8.8314 9.25916 9.39382 9.25916C9.73894 9.25916 10.0841 9.29751 10.4292 9.37417C10.7743 9.45087 11.1066 9.57872 11.4262 9.75764C11.5604 9.83434 11.6499 9.9334 11.6946 10.0548C11.7458 10.1699 11.7586 10.2945 11.733 10.4287C11.7138 10.5565 11.6627 10.6716 11.5796 10.7738C11.5029 10.8697 11.4007 10.9336 11.2728 10.9656C11.1514 10.9912 11.0108 10.9656 10.851 10.8889C10.6401 10.7738 10.4164 10.6908 10.1799 10.6396C9.94345 10.5821 9.68464 10.5534 9.40343 10.5534C8.94964 10.5534 8.56936 10.6396 8.2626 10.8122C7.95583 10.9784 7.72573 11.2276 7.57237 11.56C7.42535 11.8859 7.35187 12.2885 7.35187 12.7679C7.35187 13.4901 7.52441 14.0365 7.86953 14.4072C8.22106 14.7779 8.73875 14.9632 9.4226 14.9632C9.65267 14.9632 9.88915 14.9409 10.132 14.8961C10.3749 14.8514 10.6145 14.7875 10.851 14.7044L10.573 15.3083V13.4677H9.71019C9.51844 13.4677 9.37146 13.4198 9.2692 13.3239C9.16695 13.228 9.1158 13.097 9.1158 12.9308C9.1158 12.7583 9.16695 12.6273 9.2692 12.5378C9.37146 12.4419 9.51844 12.394 9.71019 12.394H11.2153C11.407 12.394 11.5508 12.4451 11.6467 12.5474C11.749 12.6496 11.8001 12.7966 11.8001 12.9884V15.2125C11.8001 15.3787 11.7681 15.5193 11.7042 15.6343C11.6403 15.7493 11.5317 15.8324 11.3783 15.8836C11.0907 15.9858 10.7679 16.0689 10.41 16.1328C10.0521 16.1967 9.6974 16.2287 9.3459 16.2287Z"
        fill="currentColor"
      />
      <path
        d="M13.863 16.2095C13.6201 16.2095 13.4348 16.1424 13.307 16.0082C13.1791 15.874 13.1152 15.6854 13.1152 15.4426V10.0453C13.1152 9.80239 13.1791 9.61383 13.307 9.47964C13.4348 9.34542 13.6201 9.27832 13.863 9.27832C14.0995 9.27832 14.2816 9.34542 14.4094 9.47964C14.5373 9.61383 14.6012 9.80239 14.6012 10.0453V15.4426C14.6012 15.6854 14.5373 15.874 14.4094 16.0082C14.288 16.1424 14.1059 16.2095 13.863 16.2095Z"
        fill="currentColor"
      />
      <path
        d="M16.7183 16.2095C16.4754 16.2095 16.2869 16.1424 16.1527 16.0082C16.0248 15.874 15.9609 15.679 15.9609 15.4234V10.1412C15.9609 9.89189 16.028 9.70017 16.1623 9.56594C16.2965 9.43172 16.4882 9.36462 16.7374 9.36462H20.0161C20.2078 9.36462 20.3516 9.41573 20.4475 9.51802C20.5497 9.61387 20.6009 9.75447 20.6009 9.93981C20.6009 10.1316 20.5497 10.2785 20.4475 10.3808C20.3516 10.4767 20.2078 10.5246 20.0161 10.5246H17.4468V12.1735H19.8052C19.9969 12.1735 20.1439 12.2247 20.2462 12.3269C20.3484 12.4228 20.3996 12.5634 20.3996 12.7487C20.3996 12.9341 20.3484 13.0779 20.2462 13.1801C20.1439 13.2824 19.9969 13.3335 19.8052 13.3335H17.4468V15.4234C17.4468 15.9475 17.204 16.2095 16.7183 16.2095Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default GIFIcon
