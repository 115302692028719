import { useState } from 'react'
import { useTranslation } from 'next-i18next'
import FormInput from 'shared/components/form-input'
import Modal from 'shared/components/modal'
import PrimaryButton from 'shared/components/primary-button'
import { isValidEmbeddedVideoUrl } from 'modules/attachments/utils/attachment-utils'

export interface SelectUrlModalProps {
  isOpen: boolean
  onSuccess: (url: string) => void
  onClose: () => void
}

const SelectUrlModal = ({ isOpen, onSuccess, onClose }: SelectUrlModalProps) => {
  const { t } = useTranslation()
  const [url, setUrl] = useState('')
  const [error, setError] = useState('')
  const [isFetching, setIsFetching] = useState(false)

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setIsFetching(true)

    if (!isValidEmbeddedVideoUrl(url)) {
      setError(t('post.attachments.embedded_video_link.error_unsupported'))
      setIsFetching(false)
      return
    }

    onSuccess(url)
    setIsFetching(false)
    setUrl('')
    onClose()
  }

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      title={t('post.attachments.embedded_video_link.title')}
      small
      isFetching={isFetching}
    >
      <form className="flex flex-col gap-6 lg:gap-10">
        <div className="flex flex-col justify-center gap-3">
          <FormInput
            value={url}
            label={t('global.url')}
            onChange={e => setUrl(e.target.value)}
            type="url"
            maxLength={512}
          />
          <span>{t('post.attachments.embedded_video_link.description')}</span>
        </div>
        <div className="flex justify-center gap-4">
          <PrimaryButton isLoading={isFetching} onClick={handleSubmit} type="button">
            {t('confirmation_modal.confirm')}
          </PrimaryButton>
          <PrimaryButton
            onClick={e => {
              e.preventDefault()
              onClose()
            }}
            disabled={isFetching}
            type="button"
          >
            {t('confirmation_modal.cancel')}
          </PrimaryButton>
        </div>
        {error && <p className="text-center text-sm text-red">{error}</p>}
      </form>
    </Modal>
  )
}

export default SelectUrlModal
