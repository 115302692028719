import { useEffect, useState } from 'react'
import { oembed } from '@loomhq/loom-embed'
import { OEmbedInterface } from '@loomhq/loom-embed/dist/.types/oembed'
import { Loader } from 'shared/components/loader'
import { EmbeddedVideoAttachment } from 'modules/community/types/post-attachment'

export interface EmbeddedLoomGalleryPreviewProps {
  attachment: EmbeddedVideoAttachment
}

const EmbeddedLoomGalleryPreview = ({ attachment }: EmbeddedLoomGalleryPreviewProps) => {
  const [loomMetadata, setLoomMetadata] = useState<OEmbedInterface>()

  useEffect(() => {
    const fetchLoomMetadata = async () => {
      if (!attachment.url) return

      const metadata = await oembed(attachment.url)
      setLoomMetadata(metadata)
    }

    fetchLoomMetadata()
  }, [attachment.url])

  if (!attachment.url || !loomMetadata) return <Loader />

  return (
    <article
      key={attachment.id}
      className="inline-flex w-full flex-shrink-0 snap-center justify-center px-20 py-10 lg:px-80 lg:py-40"
    >
      <div className="h-full w-full" dangerouslySetInnerHTML={{ __html: loomMetadata.html }} />
    </article>
  )
}

export default EmbeddedLoomGalleryPreview
