import React, { PropsWithChildren, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

export interface ButtonProps extends PropsWithChildren, React.HTMLAttributes<HTMLButtonElement> {
  format?: string
  active?: boolean
  onClick?: () => void
}

export const ToolbarButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, format = '', active = false, onClick, ...props }, ref) => {
    return (
      <button
        ref={ref}
        className={twMerge(
          'inline-block cursor-pointer border-none bg-[initial] text-sm font-bold leading-normal text-lightgray outline-none [&:hover>svg]:text-[initial]',
          active && 'text-[initial]',
        )}
        title={format}
        onClick={onClick}
        {...props}
      >
        {children}
      </button>
    )
  },
)

ToolbarButton.displayName = 'ToolbarButton'
